import { CheckboxContainer, coreThemes } from 'simple-core-ui'
import s from '../Table.scss'

const TableRowCheckbox = ({
  isChecked,
  selectRow,
  size = 'md',
  theme,
  isSelectable,
  showCheckbox = true,
  className,
  isCgOutline,
  isDisabled,
  greyOnDisable
}) => (
  <td
    className={s.tableRowCheckbox}
    onClick={e => {
      e.stopPropagation()
      if (!showCheckbox) return
    }}
  >
    {showCheckbox && (
      <CheckboxContainer
        size={size}
        isChecked={isChecked}
        cb={selectRow}
        hasWhiteBorder={theme === coreThemes.CG}
        hasGreyBorder={theme === coreThemes.EB}
        isCgOutline={isCgOutline}
        isSelectable={isSelectable}
        testId="row-checkbox"
        className={className}
        disabled={isDisabled}
        hasGreyBgOnDisable={greyOnDisable}
      />
    )}
  </td>
)

TableRowCheckbox.defaultProps = {
  selectRow: () => {}
}

export default TableRowCheckbox
